import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import routes from '../../routes/routes'

const SideNav = () => {
    return (
        <div className="leftpanel">
            <div className="media profile-left">
                <a className="pull-left profile-thumb" href="profile.html">
                    <img className="img-circle" src="images/photos/profile.png" alt="" />
                </a>
                <div className="media-body">
                    <h4 className="media-heading">Welcome</h4>
                    <small className="text-muted"></small>
                </div>
            </div>

            <h5 className="leftpanel-title">Navigation</h5>
            <ul className="nav nav-pills nav-stacked">
                <li>
                    <NavLink activeClassName="active" exact to={routes.public.dashboard}>
                        <i className="fa fa-home" />
                        <span>
                            Dashboard
                        </span>
                    </NavLink>
                </li>
                <li className="parent active">
                    <NavLink activeClassName="active" exact to={routes.public.subscriptions}>
                        <i className="fa fa-users"></i>
                        <span>Manage Subscriptions</span>
                    </NavLink>
                </li>
                <li className="parent active">
                    <NavLink activeClassName="active" exact to={routes.public.sections}>
                        <i className="fa fa-book"></i>
                        <span>My Sections</span>
                    </NavLink>
                    {/* <ul className="children">
                        <li>
                            <NavLink activeClassName="active" exact to="/" >
                                <i className="fa fa-book"></i>
                                <span>View Sections</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" exact to="/" >
                                <i className="fa fa-edit"></i>
                                <span>Add Sections</span>
                            </NavLink>
                        </li>

                    </ul> */}
                </li>
                {/* <li className="parent">
                    <NavLink activeClassName="active" exact to="/events" >
                        <i className="fa fa-suitcase"></i>
                        <span>Events</span>
                    </NavLink>

                    <ul className="children">
                        <li><a href="alerts.html">Alerts &amp; Notifications</a></li>
                        <li><a href="buttons.html">Buttons</a></li>
                    </ul>
                </li> */}
                {/* <li className="parent">
                    <NavLink activeClassName="active" exact to="/" >
                        <i className="fa fa-file-text-o"></i>
                        <span>Transactions</span>
                    </NavLink>
                </li> */}
                <li className="parent">
                    <NavLink activeClassName="active" exact to={routes.public.profile}>
                        <i className="fa fa-cog"></i>
                        <span> Update Profile</span>
                    </NavLink>
                </li>
                <li className="parent">
                    <NavLink activeClassName="active" exact to={routes.public.changePassword}>
                        <i className="fa fa-cog"></i>
                        <span> Update Password</span>
                    </NavLink>
                </li>

                <li className="parent">
                    <NavLink activeClassName="activeXX" exact to={routes.public.eVoting}>
                        <i className="fa fa-file-text-o"></i>
                        <span>E-Voting</span>
                    </NavLink>
                </li>
            </ul>

        </div>
    )
}

export default SideNav
