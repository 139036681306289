import axios from 'axios';
import React, {useState} from 'react'
import { useMutation } from "react-query";
import { useHistory} from "react-router-dom";
import secureLS from '../../encryption/secureLs';
import { withAuthHeader } from '../../routes/authRequest';
import routes from '../../routes/routes';


const Nav = () => {
    const [message, setMessage] = useState("");
    const mutation = useMutation(eventData => withAuthHeader.post(routes.api.locations.adminLogOut, eventData))
    const history = useHistory() 

    const onSubmit = async () => {
        await mutation.mutate({}, {
            onSuccess: async (data) => {
                // setMessage(["Event Added"])
                await secureLS.remove("authToken")
                console.log("response", data);
                setTimeout(() => {
                    history.push(routes.public.login)
                }, 2000);

            },
            onError: async (error) => {
                console.log(error);
                setMessage(["Something went wrong, please try again"])
            },
        })
    };
    return (
        <header>
            <div className="headerwrapper">
                <div className="header-left">
                    <a href="index-2.html" className="logo">
                        <img src="images/logo.png" alt="" />
                    </a>
                    <div className="pull-right">
                        <a href="/#" className="menu-collapse">
                            <i className="fa fa-bars"></i>
                        </a>
                    </div>
                </div>{/* <!-- header-left --> */}

                <div className="header-right">

                    <div className="pull-right">

                        <form className="form form-search">
                            <input type="search" className="form-control" placeholder="Search" />
                        </form>

                        <div className="btn-group btn-group-option">
                            <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                <i className="fa fa-caret-down"></i>
                            </button>
                            <ul className="dropdown-menu pull-right" role="menu">
                                <li className="text-danger">
                                    {message}
                                </li>
                                <li className="divider"></li>

                                <li><button onClick={onSubmit}><i className="glyphicon glyphicon-log-out"></i>Sign Out</button></li>
                            </ul>
                        </div>{/* <!-- btn-group --> */}

                    </div>{/* <!-- pull-right --> */}

                </div>{/* <!-- header-right --> */}

            </div>{/* <!-- headerwrapper --> */}
        </header>
    )
}

export default Nav
