import {
    Switch,
    Route,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { LayoutComponent } from "./components";
import routes from "./routes/routes";
import Dashboard from "./pages";
import Subscriptions from "./pages/subscriptions";
import { Helmet } from "react-helmet";
import Sections from "./pages/sections";
import Profile from "./pages/profile";
import Login from "./pages/login";
import EVoting from "./pages/eVoting";
import ChangePassword from "./pages/changePassword";

const queryClient = new QueryClient();
function App() {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Switch>
                    <Route exact path={routes.public.login} component={Login} />
                    <LayoutComponent>
                        <Route exact path={routes.public.dashboard} component={Dashboard} />
                        <Route exact path={routes.public.subscriptions} component={Subscriptions} />
                        <Route exact path={routes.public.sections} component={Sections} />
                        <Route exact path={routes.public.profile} component={Profile} />
                        <Route path={routes.public.eVoting} component={EVoting} />
                        <Route path={routes.public.authDashboard} component={Dashboard} />
                        <Route path={routes.public.changePassword} component={ChangePassword} />
                    </LayoutComponent>
                </Switch>
            </QueryClientProvider>

        </>
    );
}

export default App;
