import React from 'react'

const EVoting = () => {
    return (
        <div className="mainpanel">
            <div className="panel pt-3 panel-default">
                <div className="panel-heading ">
                    <div className="panel-title">
                        <span>E-Voting Information</span>
                    </div>
                </div>
                <div className="panel-body">
                    <h3>Coming soon</h3>
                </div>
            </div>
        </div>
    )
}

export default EVoting
