import PageHeader from "./header/PageHeader";
import PageLayout from "./layout/pageLayout";
import Nav from "./navbar/nav";
import Notification from "./notification/notification";
import PanelComponent from "./panel/panel";
import SideNav from "./sideNav/sideNav";
import StatsCard from "./statsCard/statsCard";
import ReactTableComponent from "./table/reactTableComponent";
import TableComponent from "./table/tableComponent";
import UserReactTableComponent from "./table/userReactTable";

export const NavBar = Nav
export const SideBar = SideNav
export const Header = PageHeader
export const StatsPanel = StatsCard
export const Alert = Notification
export const Table = TableComponent
export const LayoutComponent = PageLayout
export const ReactTable = ReactTableComponent
export const MembersTable = UserReactTableComponent
export const Panel = PanelComponent