const dashboard = "/dashboard"
const masterAdmin = "/master-admin"
const prod = !false

const server = "https://api.lagoscountryclub.net/api/"
const localhost = "http://localhost:8000/api/" ///192.168.43.95:8000
const serverDomain = "https://main.lagoscountryclub.net/"
const localImageLink = "http://localhost:8000/images/"
const remoteImageLink = "http://"

const routes = {
    master: masterAdmin,
    apiServer: (prod ? server : localhost),
    serverDomain: serverDomain,

    public: {
        dashboard: "/",
        authDashboard: "/l/:token",
        subscriptions: "/subscriptions",
        sections: "/sections",
        profile: "/profile",
        changePassword: "/changePassword",
        login: "/login",
        eVoting: "/eVoting"
    },
    api: {
        getImage: prod ? remoteImageLink : localImageLink,
        login: (prod ? server : localhost) + 'login',
        signUp: (prod ? server : localhost) + 'register',
        resetPassword: (prod ? server : localhost) + 'passwordReset',
        updateProfile: (prod ? server : localhost) + 'update_member_profile',
        adminLogin: (prod ? server : localhost) + 'auth/adminLogin',
        getSection: (prod ? server : localhost) + 'auth/sections',
        getUserSection: (prod ? server : localhost) + 'auth/getUserSection',
        locations: {
            changePassword: (prod ? server : localhost) + 'auth/changePassword',
            getSection: 'sections',
            getMember: 'user-profile',
            updateProfile: "update_member_profile",
            getUserProfile: "user-profile",
            adminLogOut: '/logout',
            login: "login"
        }
    },

    dashboard: {
        index: "/dashboard",
        dashboard: dashboard + "/dashboard",
    },
    masterAdmin: {
        index: masterAdmin,
        dashboard: masterAdmin + "/dashboard",
    }
}

export default routes