import React, {useEffect} from 'react'
import { NavBar, SideBar } from '..'
import { useHistory } from 'react-router'
import secureLS from '../../encryption/secureLs'
import routes from '../../routes/routes'

const PageLayout = ({ children }) => {
    const history = useHistory()

    useEffect(() => {
        if (!secureLS.get("authToken")) {
            history.push(routes.public.login)
            // console.log("here now");
        }
        console.log(secureLS.get("authToken"));
    }, [history])
    return (
        <body>
            <NavBar />
            <section>
                <div className="mainwrapper">
                    <SideBar />
                    {children}
                </div>
            </section>

        </body>
    )
}

export default PageLayout