import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
// import axios from "axios";

import routes from "../routes/routes";
import { useHistory } from "react-router-dom";
import { withAuthHeader } from "../routes/authRequest";
import axios from "axios";
import secureLS from "../encryption/secureLs";

const Login = () => {
    // const { state: { user="" } } = useLocation()
    let user = ""
const history  = useHistory() 
    const { register, handleSubmit, errors } = useForm();
    const [message, setMessage] = useState("");
    const mutation = useMutation(userData => axios.post(routes.api.adminLogin, userData))

    const onSubmit = async (inputData) => {
        await mutation.mutate({ ...inputData, id: user.id }, {
            onSuccess: async (inputData) => {
                setMessage(["Login Successful"])
                console.log("response ", inputData);
                secureLS.set("authToken", inputData.data.access_token )
                setTimeout(() => {
                    history.push(routes.public.dashboard)
                }, 2000);
            },
            onError: async (error) => {
                setMessage(["password or email incorrect"])
            },
        })
        console.log(inputData);
    };

    // console.log(location);
    return (
        // <div className="mainpanel">
            // <div className="contentpanel">
                <div className="col-md-8 col-md-offset-2" style={{marginTop: "10rem"}}>
                    <form className="panel panel-default" onSubmit={handleSubmit(onSubmit)}>
                        <p className="text-success">{message[0]}</p>
                        <div className="panel-heading">
                            {/* <!-- panel-btns --> */}
                            <h4 className="panel-title">Admin Login</h4>
                            <p>Input your credentials to login</p>
                        </div>
                        {/* <!-- panel-heading --> */}
                        <div className="panel-body">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        defaultValue={user.email}
                                        ref={register({
                                            required: "This input is required.",
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger">{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="password"
                                        className="form-control"
                                        ref={register()}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger">{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <!-- row --> */}
                        </div>
                        {/* <!-- panel-body --> */}
                        <div className="panel-footer">
                            <button type="submit" className="btn btn-primary">Login</button>
                        </div>
                        {/* <!-- panel-footer --> */}
                    </form>
                    {/* <!-- panel --> */}
                </div>
            // </div>
        // </div>
    );
};

export default Login;
