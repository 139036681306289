import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTable } from 'react-table'
import { withAuthHeader } from '../../routes/authRequest';
import routes from '../../routes/routes';

const ReactTableComponent = ({ data: tableData, header, sectionsToExclude}) => {
    // console.log(tableData, header);
    const [events, setEvents] = useState([])

    useEffect(() => {
        withAuthHeader.get(routes.api.getSection)
            .then(res => {
                setEvents(res.data)
                console.log(sectionsToExclude);
                // setEvents(false)
            })
            .catch(err => {
                console.log(err);
                // setEvents(false)
            })
    }, [sectionsToExclude])
    const data = React.useMemo(
        () => events, [events]
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Category',
                accessor: 'category',
            },
            // {
            //     Header: 'description',
            //     accessor: 'description',
            // },
            {
                Header: 'Price',
                accessor: 'price',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'end_date',
                accessor: 'end_date',
            },
        ],
        []
    )
    const tableInstance = useTable({ columns, data })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (
        // apply the table props
        <>
        <h1 style={{marginTop: "6rem", marginBottom: "4rem"}}>More Sections</h1>
            <table {...getTableProps()} className="table table-hover table-responsive">
                <thead className="thead-dark">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        console.log({sectionsToExclude})
                        
                            return sectionsToExclude?.includes(row.original.id)
                            ?
                            null
                            :
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell').props.cell.value === "Active"
                                                ? <span className="label label-success">{cell.render('Cell')}</span>
                                                :
                                                cell.render('Cell')
                                            }

                                        </td>
                                    )
                                })}
                                <td>
                                    <Link to={
                                        {
                                            pathname: routes.public.editEvent,
                                            state: { event: row.original }
                                        }}
                                        className={`btn btn-primary ${row.original.status === "Active" && "disabled"}`}
                                    >
                                        Add Section
                                </Link>
                                </td>
                                {/* <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={(event) => deleteEvent(row.original.id)}
                                >
                                    Delete
                                </button>
                            </td> */}
                            </tr>
                        
                    })}
                </tbody>
            </table>
        </>
    )
}

export default ReactTableComponent
