import React from 'react'

const StatsCard = ({icon, color, title, value, yesterday,HideYesterday}) => {
    return (
        <div className="col-md-4">
            <div className={`panel ${color} noborder`}>
                <div className="panel-heading noborder">
                    <div className="panel-btns">
                        <a href="/#" className="panel-close tooltips" data-toggle="tooltip" title="Close Panel"><i className="fa fa-times"></i></a>
                    </div>{/* <!-- panel-btns --> */}
                    <div className="panel-icon"><i className={icon}></i></div>
                    <div className="media-body">
                        <h5 className="md-title nomargin">{title}</h5>
                        <h2 className="mt5">{value}</h2>
                    </div>{/* <!-- media-body --> */}
                    <hr />
                    <div className="clearfix mt20">
                        <div className="pull-left">
                            {!HideYesterday && <h5 className="md-title nomargin">Yesterday</h5>}
                            <h4 className="nomargin">{yesterday}</h4>
                        </div>
                        {/* <div className="pull-right">
                            <h5 className="md-title nomargin">This Week</h5>
                            <h4 className="nomargin">$99,103.67</h4>
                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StatsCard
