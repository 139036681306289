import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
// import axios from "axios";

import { Header } from "../components";
import routes from "../routes/routes";
import { useHistory } from "react-router-dom";
import { withAuthHeader } from "../routes/authRequest";

const ChangePassword = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm();
    const [message, setMessage] = useState("");
    const [memberInfo, setMemberInfo] = useState({});
    const mutation = useMutation(userData => withAuthHeader.post(routes.api.locations.changePassword, 
        {id: memberInfo.id, ...userData}
        ))

    useEffect(() => {
        withAuthHeader.get(routes.api.locations.getUserProfile)
            .then(({ data }) => {
                setMemberInfo(data)
            })
            .catch(error => console.log(error))
    },[])
    const onSubmit = async (inputData) => {
        if(inputData.password !== inputData.confirmPassword){
            return setMessage(["Passwords must match"])
        }
        await mutation.mutate({ ...inputData }, {
            onSuccess: async (inputData) => {
                setMessage(["Password updated"])
                console.log("input", inputData);
                setTimeout(() => {
                    history.push(routes.public.users)
                }, 2000);
            },
            onError: async (error) => {
                setMessage(["Something went wrong, please try again"])
                console.log(error);
            },
        })
        console.log(inputData);
    };

    // console.log(location);
    return (
        <div className="mainpanel">
            {/* <!-- pageheader --> */}
            <Header page="Profile" />

            <div className="contentpanel">
                <div className="col-md-10 col-md-offset-1">
                    <form className="panel panel-default" onSubmit={handleSubmit(onSubmit)}>
                        <p className="text-success">{message[0]}</p>

                        <div className="panel-heading">
                            <div className="panel-btns">
                                <a
                                    href="/#"
                                    className="panel-minimize tooltips"
                                    data-toggle="tooltip"
                                    title="Minimize Panel"
                                >
                                    <i className="fa fa-minus">-</i>
                                </a>
                                <a
                                    href="/#"
                                    className="panel-close tooltips"
                                    data-toggle="tooltip"
                                    title="Close Panel"
                                >
                                    <i className="fa fa-times"></i>
                                    X
                </a>
                            </div>
                            {/* <!-- panel-btns --> */}
                            <h4 className="panel-title">Update Password</h4>
                            <p>Fill and submit the fom to update password.</p>
                        </div>
                        {/* <!-- panel-heading --> */}
                        <div className="panel-body">
                            <div className="row">

                                <div className="form-group col-md-6">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        className="form-control"
                                        ref={register({
                                            required: "This input is required.",
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger">{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>


                                <div className="form-group col-md-6">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        ref={register({
                                            required: "This input is required.",
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirmPassword"
                                        render={(messages) => {
                                            console.log("messages", messages);
                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p className="text-danger">{message}</p>
                                                ))
                                                : null;
                                        }}
                                    />
                                </div>

                            </div>
                            {/* <!-- row --> */}
                        </div>
                        {/* <!-- panel-body --> */}
                        <div className="panel-footer">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                        {/* <!-- panel-footer --> */}
                    </form>
                    {/* <!-- panel --> */}
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
