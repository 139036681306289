import axios from "axios";
import secureLS from "../encryption/secureLs";
import routes from "./routes";

export const withAuthHeader = axios.create({
    baseURL: routes.apiServer + "auth/",
    timeout: 10000,
    headers: { 'Authorization': 'bearer ' + secureLS.get("authToken")}
});
console.log(secureLS.get("authToken"));


/*
withAuthHeader.post(routes.api.locations.login, {member_id: "7266539712", password: "12345678"})
       .then(({data}) => {
           localStorage.setItem("authToken", data.access_token)
       })
       .catch(error => console.log(error))
        */