import React, { useEffect, useState } from 'react'
import { Header, ReactTable } from '../components'
import { withAuthHeader } from '../routes/authRequest'
import routes from '../routes/routes'

const Sections = () => {
    const [sections, setSections] = useState([])
    const [sectionsToExclude, setSectionsToExclude] = useState([])
    useEffect(() => {
        withAuthHeader.get(routes.api.getUserSection)
            .then(res => {
                setSections(res.data.sections)
                let sectionId = []
                res.data.sections.forEach(section => {
                    sectionId.push(section.id)
                })
                setSectionsToExclude([...sectionId])
                console.log(res.data.sections);
            })
            .catch(err => {
                console.log(err);
                // setEvents(false)
            })
    }, [])
    return (
        <div className="mainpanel">
            <Header page="Section" />
            {/* <!-- pageheader --> */}
            <div className="contentpanel">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-btns">
                            <a href="/#" className="panel-minimize tooltips" data-toggle="tooltip" title="Minimize Panel"><i className="fa fa-minus"></i></a>
                            <a href="/#" className="panel-close tooltips" data-toggle="tooltip" title="Close Panel"><i className="fa fa-times"></i></a>
                        </div>
                        <h3 className="panel-title">My Sections</h3>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-12 col-md-10">
                            {
                                sections.map(section => (
                                <span key={section.id} style={{ marginRight: "2rem", fontSize: "24px" }} className="label h3 h1 label-primary">
                                    {section.name}
                                </span>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                </div>

                <ReactTable sectionsToExclude={sectionsToExclude} />
                {/* <Panel /> */}
            </div>
        </div>
    )
}

export default Sections
