import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Header, ReactTable, StatsPanel } from "../components";
import secureLS from "../encryption/secureLs";
import { withAuthHeader } from "../routes/authRequest";
import routes from "../routes/routes";

function Dashboard() {
  const {token} = useParams()
  const location = useLocation()

  console.log(location.pathname.replace("/l/", ''));
  
  useEffect(() => {
    if(token){
      // secureLS.set("authToken", token)
      localStorage.setItem("authToken", location.pathname.replace("/l/", ''))
      window.location.href = "/"
    }
  }, [location.pathname, token])
  return (
    <div className="mainpanel">
      {/* <!-- pageheader --> */}
      <Header page="Dashboard" />
      {/* <p>{token}</p> */}
      <div className="contentpanel">
        <div className="row row-stat">
          <StatsPanel
            icon="fa fa-unlock-alt"
            value="Active"
            yesterday="Access to club facilities granted"
            color="panel-success-alt"
            HideYesterday
            title="Status"
          />
          <StatsPanel
            icon="fa fa-lock"
            value="189"
            yesterday="Renew before subscription expires"
            color="panel-primary"
            HideYesterday
            title="Days Left"
          />
          <StatsPanel
            icon="fa fa-trophy"
            value="3"
            yesterday="Total active sections"
            color="panel-dark"
            HideYesterday
            title="Section"
          />
        </div>

        <div className="panel panel-dark-head">
          <div className="panel-heading">
            <div className="pull-right">
              <div className="btn-group">
                {/* <button
                  data-toggle="dropdown"
                  className="btn btn-sm mt5 btn-white noborder dropdown-toggle"
                  type="button"
                >
                  View/Add Columns <span className="caret"></span>
                </button> */}
              </div>
            </div>
            <h4 className="panel-title">View/Add Sections</h4>
            {/* <p>
              This example shows how to dynamically show and hide columns in a
              table.
            </p> */}
          </div>
          {/* <!-- panel-heading --> */}

         <ReactTable />
        </div>
      </div>
      {/* <!-- contentpanel --> */}
    </div>
  );
}

export default Dashboard;
