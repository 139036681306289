import React, { useEffect, useState } from 'react'
import { Header } from '../components'
import routes from '../routes/routes'
import { withAuthHeader } from '../routes/authRequest'

const Subscriptions = () => {
  const [user, setUser] = useState([])
  useEffect(() => { 
    withAuthHeader.get(routes.api.locations.getMember)
    .then(res => {
      setUser(res.data)
        console.log(res.data);
        // setEvents(false)
    })
    .catch(err => {
        console.log(err);
        // setEvents(false)
    })
  }, [])
    return (
        <div className="mainpanel">
        <Header page="Subscriptions" />
            {/* <!-- pageheader --> */}
            <div className="contentpanel">
                {/* <Panel /> */}
                <div className="panel panel-default">
                    <div className="panel-heading ">
                        <div className="panel-title">
                            <span>Subscription Information</span>
                            <button type="button" className="btn btn-success pull-right">Pay Subscription</button>
                        </div>
                        {/* <div className="pull-right"> */}
                        {/* </div> */}

                    </div>
                    <div className="panel-body">
                        <h3>Active</h3>
                    </div>
                </div>

                <h3>Subscription History</h3>
                <table id="shTable" className="table table-striped table-bordered">
            <thead className="">
              <tr>
                <th>Name</th>
                <th>Id</th>
                <th>Address</th>
                <th>Subscribed</th>
                <th>Expiry Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
              <tr>
                <td>{user.surname}</td>
                <td>{user.member_id}</td>
                <td>{user.address}</td>
                <td>2011/04/25</td>
                <td>2011/04/25</td>
              </tr>
              
            </tbody>
          </table>
            </div>
        </div>
    )
}

export default Subscriptions
