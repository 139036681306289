import React from 'react'

const PageHeader = ({page}) => {
    return (
        <div className="pageheader">
            <div className="media">
                <div className="pageicon pull-left">
                    <i className="fa fa-home"></i>
                </div>
                <div className="media-body">
                    <ul className="breadcrumb">
                        <li><a href="/#"><i className="glyphicon glyphicon-home"></i></a></li>
                        <li>Dashboard</li>
                    </ul>
                    <h4>{page}</h4>
                </div>
            </div>
        </div>
    )
}

export default PageHeader
